import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var usePaymentNotesPermissions = function (user) {
    var hasPermissionToDeletePaymentNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.PAYMENT_NOTE, MethodCode.DELETE);
    var hasPermissionToEditPaymentNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.PAYMENT_NOTE, MethodCode.EDIT);
    var hasPermissionToViewPaymentNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.PAYMENT_NOTE, MethodCode.VIEW);
    var hasPermissionToCreatePaymentNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.PAYMENT_NOTE, MethodCode.CREATE);
    return {
        hasPermissionToDeletePaymentNote: hasPermissionToDeletePaymentNote,
        hasPermissionToEditPaymentNote: hasPermissionToEditPaymentNote,
        hasPermissionToViewPaymentNote: hasPermissionToViewPaymentNote,
        hasPermissionToCreatePaymentNote: hasPermissionToCreatePaymentNote,
    };
};
export default usePaymentNotesPermissions;
